import '../styles/index.scss'


function is_touch_device() {
  var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
  var mq = function(query) {
    return window.matchMedia(query).matches
  }

  if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
    return true
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('')
  return mq(query)
}

jQuery(function ($) {
  const desktopBreakpoint = 800
  const isHomeSickness = $('body').is('.homesickness')
  let lastWidth
  const slickOpts = {
    arrows: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1
  }
  if (!isHomeSickness) {
    slickOpts.slidesToShow = 2
    slickOpts.slidesToScroll = 2
    slickOpts.responsive = [{
      breakpoint: desktopBreakpoint,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }]
  }
  // active / inactive things
  $('*[data-start]').each(function () {
    var start = moment($(this).attr('data-start') + ' 00:00:00')
    var end = moment($(this).attr('data-end') + ' 23:59:59') 
    var now = Date.now()
    // live
    if (now > start.valueOf() && now < end.valueOf()) {
      if ($(this).attr('data-image')) {
        $(this).attr('data-image', $(this).attr('data-image').replace(/(\d)\.jpg/, '$1.mp4'))
        console.log($(this).attr('data-image'));
      }

    // not yet
    } else {
      $(this).addClass('inactive')
    }
  })
  const slideCount = $('.slider > div.s').length
  function stopAllSound () {
    $('video').prop('muted', true)
    $('a.speaker').removeClass('on')
  }
  const doSlides = function (evt, slick) {
    $('.slider .s').each(function () {
      if ($(this).attr('data-image')) {
        const url = $(this).attr('data-image')
        if (/\.mp4/.test(url)) {
          $(this).find('video').remove()
          $(this).find('a.speaker').remove()
          var videoOpts = {
            width: $(this).width(),
            height: $(this).height(),
            src: url,
            autoplay: true,
            playsInline: true,
            muted: true,
            loop: true,
            controls: false
          }
          if (isHomeSickness) {
            videoOpts.controls = true
            videoOpts.autoplay = false
            videoOpts.muted = false
            videoOpts.poster = url.replace('.mp4', '.jpg')
          }

          const v = $('<video>').prop(videoOpts)
          v.css({
            objectFit: 'cover',
            position: 'absolute',
            left: 0,
            top: 0
          })
          $(this).css({ 
            position: 'relative',
            overflow: 'hidden'
          }).append(v)
          var speaker = $('<a>').addClass('speaker').click(function (evt) {
            var hadOn = $(this).hasClass('on')
            stopAllSound()
            if (!hadOn) {
              v.prop('muted', false)
              $(this).addClass('on')
            }
            evt.preventDefault()
            return false
          })
          $(this).append(speaker)

        } else {
          $(this).css({
            backgroundImage: 'url(' + $(this).attr('data-image') + ')'
          })
        }
      }
    })
    $('.slider').css({
      backgroundImage: 'url(' + $('.s.title').attr('data-image') + ')'
    })
    const w = $(window).width()
    if ($('.slider').slick && w !== lastWidth) {
      lastWidth = w
      // to mobile
      if (w <= desktopBreakpoint) {
        setTimeout(() => {
          $('.slider').slick('slickAdd', `<div><div class='s itinerary'>` + $('.desktop-title .itinerary').html() + `</div></div>`, 0)
          $('body').addClass('small').removeClass('big')
        }, 0)
        
      // to desktop
      } else {
        if ($('body').hasClass('small')) {
          $('.slider').slick('slickRemove', 1)
        }
        $('body').removeClass('not-first').removeClass('small').addClass('big')
      }
    }
  }
  let currSlide
  let slideCommentaries = {}
  $('.slider > div.s').each(function (i) {
    if ($(this).find('.slide-commentary')) {
      slideCommentaries[i] = $(this).find('.slide-commentary').html()
    }
  })
  const beforeChange = (event, slick, currentSlide, nextSlide) => {
    stopAllSound()
    currSlide = nextSlide
    const slideThatMatters = $('body').hasClass('small') ? 1 : 0
    if (slideCommentaries[nextSlide]) {
      $('.commentary').html(slideCommentaries[nextSlide])
    }
    if (nextSlide > slideThatMatters) {
      $('.commentary').show()
      $('.itinerary').hide()
    } else {
      $('.commentary').hide()
      $('.itinerary').show()
    }
    if (nextSlide === 0) {
      $('body').removeClass('not-first')
    } else {
      $('body').addClass('not-first')
    }
    if (nextSlide === 2) {
      $($('body').is('.small') ? '.tap-to' : '.click-to').fadeOut(5000)
    }
  }
  $('.slider')
    .on('init', doSlides)
    .on('breakpoint', doSlides)
    .on('beforeChange', beforeChange)
    .slick(slickOpts)

  $('.desktop-title').prepend($('.s.title').html())
    // .find('.itinerary > div').append("<p class='click-to'>CLICK TO READ CURATOR'S COMMENTARY</p>")

  let intro = false
  function toggleIntro (evt) {
    var $a = $(evt.target).closest('a')
    if ($a[0] && $a.attr('data-slide') && !$a.hasClass('inactive')) {
      var i = parseInt($a.attr('data-slide'), 10)
      if ($('body').hasClass('small')) {
        i++
      }
      $('.slider').slick('slickGoTo', i)
      return false
    }
    let slideThatMatters = $('body').hasClass('small')? slideCount : slideCount - 2
    if (isHomeSickness) {
      slideThatMatters = 4
    }
    if (slideThatMatters === currSlide) return true

    $('#intro').toggle()
    intro = !intro
    if ($(this).is('.close')) return false
  }
  $('body').on('click', '.wrap,.close', toggleIntro)
  if (!is_touch_device()) {
    $('body').addClass('no-touch')
    let _going = false
    $(window).on('wheel', function (evt) {

      const $desktopTitle = $(evt.target).closest('.desktop-title')
      if ($desktopTitle[0]) {
        const $it = $desktopTitle.find('.itinerary')
        if ($it.find('div').outerHeight() - $it.height() + $it.offset().top > 2) return
      }
      if (intro) return
      if (!_going) {
        // backward
        if (evt.originalEvent.deltaY < 0) {
          $('.slider').slick('slickPrev')

          // forward
        } else {
          $('.slider').slick('slickNext')
        }
      }
      clearTimeout(_going)
      _going = setTimeout(function () {
        _going = false
      }, 40)
    })
  } else {
    $('body').addClass('touch')
  }
})
